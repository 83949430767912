<template>
  <div>
    <validation-observer ref="simpleRules">
      <button
        class="btn btn-gradient-primary mb-1"
        @click="Submit()"
      >
        {{ $t('saveData') }}
      </button>
      <b-row>
        <b-col md="8">

          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('personalInfo') }}
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="h-product-name"
                    label-cols-md="3"
                    label-cols="3"
                    label-cols-lg="3"
                  >
                    <template v-slot:label>
                      {{ $t('firstName') + '-' + $t('lastName') }} <span class="text-danger">*</span> :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="fullname"
                      rules="required"
                    >
                      <b-form-input
                        id="h-product-name"
                        v-model="items.user.fullname"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-product-id"
                    label-cols-md="3"
                    label-cols="3"
                    label-cols-lg="3"
                  >
                    <template v-slot:label>
                      {{ $t('email') }} <span class="text-danger">*</span> :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="h-product-id"
                        v-model="items.user.email"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-phone-number"
                    label-cols-md="3"
                    label-cols="3"
                    label-cols-lg="3"
                  >
                    <template v-slot:label>
                      {{ $t('phoneNumber') }} <span class="text-danger">*</span> :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Phone Number"
                      rules="required"
                    >
                      <b-form-input
                        id="h-phone-number"
                        v-model="items.user.tel"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-phone-number2"
                    label-cols-md="3"
                    label-cols="3"
                    label-cols-lg="3"
                  >
                    <template v-slot:label>
                      {{ $t('phoneNumber') }}(2) :
                    </template>
                    <!-- <validation-provider
                      #default="{ errors }"
                      name="Phone"
                      rules="required"
                    > -->
                    <b-form-input
                      id="h-phone-number2"
                      v-model="items.user.tel2"
                      type="text"
                    />
                    <!-- :state="errors.length > 0 ? false : null" -->
                    <!-- <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider> -->
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-IDLine"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      ID LINE <span class="text-danger">*</span> :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="IDLine"
                      rules="required"
                    >
                      <b-form-input
                        id="h-IDLine"
                        v-model="items.user.line_id"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-IDWeChat"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      ID WeChat :
                    </template>
                    <!-- <validation-provider
                      #default="{ errors }"
                      name="IDWeChat"
                      rules="required"
                    > -->
                    <b-form-input
                      id="h-IDWeChat"
                      v-model="items.user.wechat_id"
                      type="text"
                    />
                    <!-- :state="errors.length > 0 ? false : null" -->
                    <!-- <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider> -->
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-different"
                    label-cols-md="3"
                    label-cols="3"
                    label-cols-lg="3"
                  >
                    <template v-slot:label>
                      {{ $t('key-237') }} :
                    </template>
                    <!-- <validation-provider
                      #default="{ errors }"
                      name="Different"
                      rules="required"
                    > -->
                    <b-form-input
                      id="h-different"
                      v-model="items.user.invited_by"
                      type="text"
                    />
                    <!-- :state="errors.length > 0 ? false : null" -->
                    <!-- <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider> -->
                  </b-form-group>
                </b-col>
                <!--
                <b-col md="12">
                  <b-form-group
                    label-for="h-invite"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      รหัสผู้แนะนำ <span class="text-danger">*</span> :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Invite Code"
                      rules="required"
                    >
                      <v-select
                        id="h-invite"
                        v-model="invited_by"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false : null"
                        label="name"
                        :options="items"
                        class="w-100 mr-1"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
                <b-col md="12">
                  <b-form-group
                    label-for="h-different"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('different') }} <span class="text-danger">*</span> :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Different"
                      rules="required"
                    >
                      <b-form-input
                        id="h-different"
                        v-model="items.user.different"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-invite"
                    label-cols-md="3"
                    label-cols="3"
                    label-cols-lg="3"
                  >
                    <template v-slot:label>
                      {{ $t('level') }} <span class="text-danger">*</span> :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Invite Code"
                      rules="required"
                    >
                      <v-select
                        v-model="items.user.role"
                        label="text"
                        :options="rolelist"
                        :reduce="text => text.value"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>

          <div class="card">
            <div class="header-cardx p-2 d-flex justify-content-between">
              <h3 class="mb-0">
                {{ $t('key-237') }}
              </h3>
              <div>
                <b-form-checkbox
                  v-model="items.user.invite"
                  :value="1"
                  class="custom-control-success"
                  name="check-button"
                  switch
                />
              </div>
            </div>

            <div
              v-if="items.user.invite"
              class="p-2"
            >
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="h-different"
                    label-cols="5"
                    label-cols-md="3"
                    label-cols-lg="3"
                  >
                    <template v-slot:label>
                      {{ $t('key-237') }} <span class="text-danger">*</span> :
                    </template>
                    <b-form-input
                      id="h-different"
                      v-model="items.user.invite_code"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>

          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('accessDetails') }}
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="h-product-name"
                    label-cols-md="3"
                    label-cols="3"
                    label-cols-lg="3"
                  >
                    <template v-slot:label>
                      {{ $t('customerCode') }} <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Product Name"
                      rules="required"
                    >
                      <b-form-input
                        id="h-product-name"
                        v-model="items.user.username"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="login-password"
                    label-cols-md="3"
                    label-cols="3"
                    label-cols-lg="3"
                  >
                    <template v-slot:label>
                      {{ $t('password') }}
                      <span class="text-danger">*</span>
                    </template>

                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>

          <div class="card">
            <div class="header-cardx p-2 d-flex justify-content-between align-items-center">
              <h3 class="mb-0">
                {{ $t('key-197') }} <span class="text-danger">*</span>
              </h3>
              <button
                v-if="!items.user.zipcode"
                class="btn btn-gradient-primary"
                @click="showAddAddrCart()"
              >
                <i class="far fa-plus" /> {{ $t('addAddress') }}
              </button>
            </div>
            <div
              v-if="items.user.zipcode"
              class="view"
            >
              <div class="icon">
                <i class="fas fa-map-marker-alt fa-2x text-success" />
              </div>
              <div class="content">
                <div class="grider">
                  <div class="label">
                    {{ $t('address') }}
                  </div>
                  <div class="detail">
                    {{ items.user.address }} <br>
                    {{ $t('province') }} {{ items.user.province_name }} <br>
                    {{ $t('district') }} {{ items.user.district_name }} <br>
                    {{ $t('subdistrict') }} {{ items.user.subdistrict_name }} <br>
                    {{ $t('postalCode') }} {{ items.user.zipcode }}
                  </div>
                </div>
              </div>
              <div class="operations text-r">
                <div class="edit">
                  <button
                    type="button"
                    class="anan-button anan-button--link anan-button--normal"
                    @click="showAddAddrCart(items.user,index)"
                  >
                    <span> {{ $t('edit') }} </span>
                  </button>
                </div>
                <div class="delete">
                  <button
                    type="button"
                    class="anan-button anan-button--link anan-button--normal"
                    @click="deleteAddrCart()"
                  >
                    <span> {{ $t('delete') }} </span>
                  </button>
                </div>
              </div>
            </div>
            <!-- <div class="p-2">
              <b-row>
                <b-col md="12">

                  <b-form-group
                    label-for="h-addressCart"
                    :label="this.$t('addressNumber')"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="AddressCart"
                      rules="required"
                    >
                      {{items.user.address}}
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  md="12"
                >
                  <b-form-group
                    label-for="h-province-nameCart"
                    :label="this.$t('province')"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="ProvinceCart"
                      rules="required"
                    >
                      <v-select
                        v-model="items.user.province"
                        label="province_name"
                        :options="provinceOptionsCart"
                        :is-disabled="errors.length > 0"
                        :reduce="ele => ele.province_id"
                        @input="updateDistricts"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  md="12"
                >
                  <b-form-group
                    label-for="h-district-name"
                    :label="this.$t('district')"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="District"
                      rules="required"
                    >
                      <v-select
                        v-model="selectedDistrict"
                        label="district_name"
                        :options="filteredDistricts"
                        :is-disabled="!selectedProvince"
                        :reduce="ele => ele.district_id"
                        @input="updateSubDistricts"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  md="12"
                >
                  <b-form-group
                    label-for="h-sub-district-name"
                    :label="this.$t('subdistrict')"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Sub District"
                      rules="required"
                    >
                      <v-select
                        id="h-sub-district-name"
                        v-model="selectedSubDistrict"
                        label="sub_district_name"
                        :options="filteredSubDistricts"
                        :state="errors.length > 0 ? false : null"
                        :reduce="ele => ele.tumbon_id"
                        @input="updatePostalcode"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label-for="h-postal-code"
                    :label="this.$t('postalCode')"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Postal Code"
                      rules="required"
                    >
                      <b-form-input
                        id="h-postal-code"
                        v-model="postalcode"
                        label="zipcode"
                        :state="errors.length > 0 ? false : null"
                        :disabled="postalcode"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div> -->
          </div>

          <div class="card">
            <div class="header-cardx p-2 d-flex justify-content-between align-items-center">
              <div>
                <h3 class="mb-0">
                  {{ $t('shippingAddress') }}
                </h3>
                <small class="text-muted">{{ $t('setDeliveryAddress') }} </small>
              </div>

              <button
                class="btn btn-gradient-primary"
                @click="showAddAddr()"
              >
                <i class="far fa-plus" /> {{ $t('addAddress') }}
              </button>
            </div>

            <div
              v-for="(items,index) in items.addresses"
              :key="items"
              class="view"
            >
              <div class="icon">
                <i class="fas fa-map-marker-alt fa-2x text-success" />
              </div>
              <div class="content">
                <div class="grider">
                  <div class="label">
                    {{ $t('firstName') + '-' + $t('lastName') }}
                  </div>
                  <div class="name">
                    {{ items.fullname }}
                  </div>
                </div>
                <div class="grider">
                  <div class="label">
                    {{ $t('mobileNumber') }}
                  </div>
                  <div class="phone">
                    {{ items.tel }}
                  </div>
                </div>
                <div class="grider">
                  <div class="label">
                    {{ $t('address') }}
                  </div>
                  <div class="detail">
                    {{ items.address }} <br>
                    {{ $t('province') }} {{ items.province }} <br>
                    {{ $t('district') }} {{ items.district }} <br>
                    {{ $t('subdistrict') }} {{ items.subdistrict }} <br>
                    {{ $t('postalCode') }} {{ items.postalcode }}
                  </div>
                </div>
              </div>
              <div class="operations text-r">
                <div class="edit">
                  <button
                    type="button"
                    class="anan-button anan-button--link anan-button--normal"
                    @click="showAddAddr(items,index)"
                  >
                    <span> {{ $t('edit') }} </span>
                  </button>
                </div>
                <div class="delete">
                  <button
                    type="button"
                    class="anan-button anan-button--link anan-button--normal"
                    @click="deleteAddr(items,index)"
                  >
                    <span> {{ $t('delete') }} </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="header-cardx p-2 d-flex justify-content-between align-items-center">
              <div>
                <h3 class="mb-0">
                  {{ $t('bankAccount') }}
                </h3>
              </div>

              <button
                v-if="itemBank.length < 2"
                class="btn btn-gradient-primary"
                @click="EditType(),titleBank = $t('addBankAccount')"
              >
                <i class="far fa-plus" /> {{ $t('key-239') }}
              </button>
            </div>

            <div class="pl-2 pr-2">
              <b-table
                responsive="sm"
                :fields="fieldsbank"
                :items="itemBank"
                show-empty
              >
                <template #empty="">
                  <b-table-empty-slot>
                    <div class="text-center my-2">
                      <img
                        src="@/assets/images/anan-img/svg/doc_empty.svg"
                        alt="empty"
                        height="60px"
                      >
                      <p class="text-muted">
                        No Data
                      </p>
                    </div>
                  </b-table-empty-slot>
                </template>
                <template #cell(set)="data">
                  <b-form-checkbox
                    v-model="checkeditem"
                    :value="data.item"
                    :unchecked-value="null"
                  />
                </template>
                <template #cell(branch)="data">
                  {{ data.item.branch ? data.item.branch : "-" }}
                </template>

                <template #cell(actions)="data">
                  <feather-icon
                    icon="Edit3Icon"
                    class="mr-1 text-primary cursor-pointer"
                    @click="EditType(data.item , data.index),titleBank = $t('key-125')"
                  />

                  <feather-icon
                    icon="TrashIcon"
                    class="text-danger cursor-pointer"
                    @click="DeleteData(data.item)"
                  />
                  <!-- {{ data.item.branch ? data.item.branch: '-' }} -->
                </template>
              </b-table>
            </div>
          </div>

          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('userGroup') }}
              </h3>
            </div>

            <div class="p-2">
              <b-form-group
                label-for="h-catagory-name"
                label-cols-md="3"
                label-cols="3"
                label-cols-lg="3"
              >
                <template v-slot:label>
                  {{ $t('userGroup') }} <span class="text-danger">*</span>
                </template>
                <div class="d-flex align-items-center">
                  <v-select
                    id="h-catagory-name"
                    v-model="group_use"
                    label="name"
                    :options="group_user_list"
                    class="w-100 mr-1"
                  />
                  <!-- @click="GetGroupUserList()" -->
                  <router-link
                    v-if="!group_use"
                    :to="{name: 'admin-user-type'}"
                    target="_blank"
                  >
                    <i class="far fa-edit text-primary cursor-pointer" />
                  </router-link>
                  <router-link
                    v-else
                    :to="{name: 'admin-user-type-edit', params: {id: group_use._id}}"
                    target="_blank"
                  >
                    <i class="far fa-edit text-primary cursor-pointer" />
                  </router-link>
                  <i
                    :class="`fas fa-sync-alt ${group_userloading ? 'fa-spin' : ''} text-primary cursor-pointer ml-1`"
                    @click="GetGroupUserList()"
                  />
                </div>
              </b-form-group>

              <b-form-group
                v-if="group_use"
                label-for="h-catagory-name"
                label-cols-md="3"
                label-cols="3"
                label-cols-lg="3"
              >
                <template v-slot:label>
                  {{ $t('type') }} <span class="text-danger">*</span>
                </template>
                <div class="d-flex align-items-center">
                  <b-form-radio
                    v-model="group_user_type"
                    name="some-radios"
                    :value="1"
                    class="mb-1 mt-1"
                    @input="CalGroupUser()"
                  >
                    {{ $t('defaultValue') }}
                  </b-form-radio>

                  <b-form-radio
                    v-model="group_user_type"
                    name="some-radios"
                    class="ml-1"
                    :value="2"
                  >
                    {{ $t('customized') }}
                  </b-form-radio>
                </div>
              </b-form-group>

              <div
                v-if="group_use"
                class="row"
              >
                <div class="col-md-6">
                  <template v-if="group_user_type == 1">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">
                            {{ $t('truck') }}
                          </th>
                          <th scope="col">
                            {{ $t('kilo') }}.
                          </th>
                          <th scope="col">
                            {{ $t('cubic') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="car in preorder(group_use.product_type, 1).slice().reverse()"
                          :key="car.id"
                        >
                          <td>{{ car.product_type_name }}</td>
                          <td>{{ car.product_type_kilo }}</td>
                          <td>{{ car.product_type_queue }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                  <template v-if="group_user_type == 2">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">
                            {{ $t('truck') }}
                          </th>
                          <th scope="col">
                            {{ $t('kilo') }}.
                          </th>
                          <th scope="col">
                            {{ $t('cubic') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in preorder(group_use.product_type, 1).slice().reverse()"
                          :key="item.id"
                        >
                          <td>{{ item.product_type_name }}</td>
                          <td>
                            <b-form-input v-model="item.product_type_kilo" />
                          </td>
                          <td>
                            <b-form-input v-model="item.product_type_queue" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </template>

                </div>
                <div class="col-md-6">
                  <template v-if="group_user_type == 1">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">
                            {{ $t('ship') }}
                          </th>
                          <th scope="col">
                            {{ $t('kilo') }}.
                          </th>
                          <th scope="col">
                            {{ $t('cubic') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in preorder(group_use.product_type, 2).slice().reverse()"
                          :key="item.id"
                        >
                          <td>{{ item.product_type_name }}</td>
                          <td>{{ item.product_type_kilo }}</td>
                          <td>{{ item.product_type_queue }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                  <template v-if="group_user_type == 2">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">
                            {{ $t('ship') }}
                          </th>
                          <th scope="col">
                            {{ $t('kilo') }}.
                          </th>
                          <th scope="col">
                            {{ $t('cubic') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in preorder(group_use.product_type, 2).slice().reverse()"
                          :key="item.id"
                        >
                          <td>{{ item.product_type_name }}</td>

                          <td>
                            <b-form-input v-model="item.product_type_kilo" />
                          </td>
                          <td>
                            <b-form-input v-model="item.product_type_queue" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                  <!-- <b-table
                    responsive="sm"
                    :fields="fieldsShip"
                    :items="itemsShip"
                  >
                    <template
                      v-if="group_user_type == 2"
                      #cell(kg)="data"
                    >
                      <b-form-input
                        v-model="data.item.kg"
                        type="number"
                      />
                    </template>

                    <template
                      v-if="group_user_type == 2"
                      #cell(queue)="data"
                    >
                      <b-form-input
                        v-model="data.item.queue"
                        type="number"
                      />
                    </template>
                  </b-table> -->
                </div>
              </div>

            </div>
          </div>
        </b-col>

        <b-col md="4">
          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('moduleDetails') }}
              </h3>
            </div>

            <div class="p-2 card-module">
              <b-form-group
                label-for="h-catagory-name"
                label-cols-md="3"
              >
                <template v-slot:label>
                  {{ $t('License') }} <span class="text-danger">*</span>
                </template>
                <div class="d-flex align-items-center">
                  <v-select
                    id="h-catagory-name"
                    v-model="module_use"
                    :options="module_list"
                    label="name"
                    :reduce="text => text"
                    class="w-100 mr-1"
                    @input="CalModule()"
                  />
                  <!-- <i
                    class="far fa-edit text-primary cursor-pointer"
                    @click="showModal"
                  /> -->
                </div>
              </b-form-group>

              <div>
                <div
                  v-for="item in subrole"
                  :key="item.name"
                  class="d-flex justify-content-between mb-1 mt-2 align-items-center border-bottom"
                >
                  <div>
                    <h5 class="mb-0 font-weight-bolder">
                      {{ item.name }}
                    </h5>
                  </div>
                  <div>
                    <i
                      :class="`fas ${item.status ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'} `"
                    />
                    <!-- @click="item.status = !item.status , SubRole(item)" -->
                  </div>
                </div>
              </div>

            </div>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
    <!-- เพิ่มที่อยู่ใหม่ -->
    <b-modal
      id="add-addr"
      :title="$t('key-238')"
    >
      <validation-observer ref="addRules">
        <b-form-group
          label-for="h-name"
          :label="this.$t('firstName') + '-' + this.$t('lastName')"
        >
          <validation-provider
            #default="{ errors }"
            name="Full Name"
            rules="required"
          >
            <b-form-input
              id="h-name"
              v-model="fullname"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-phone-number"
          :label="this.$t('mobileNumber')"
        >
          <validation-provider
            #default="{ errors }"
            name="Phone Number"
            rules="required"
          >
            <b-form-input
              id="h-phone-number"
              v-model="tel"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-address"
          :label="this.$t('addressNumber')"
        >
          <validation-provider
            #default="{ errors }"
            name="Address"
            rules="required"
          >
            <b-form-textarea
              id="h-address"
              v-model="address"
              row="2"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label-for="h-province-name"
          :label="this.$t('province')"
        >
          <validation-provider
            #default="{ errors }"
            name="Province"
            rules="required"
          >
            <v-select
              id="h-province-name"
              v-model="selectedProvince"
              label="province_name"
              :options="provinceOptions"
              :state="errors.length > 0 ? false : null"
              :reduce="ele => ele.province_id"
              @input="updateDistricts"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-district-name"
          :label="this.$t('district')"
        >
          <validation-provider
            #default="{ errors }"
            name="District"
            rules="required"
          >
            <v-select
              id="h-district-name"
              v-model="selectedDistrict"
              label="district_name"
              :options="filteredDistricts"
              :state="errors.length > 0 ? false : null"
              :reduce="ele => ele.district_id"
              @input="updateSubDistricts"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-sub-district-name"
          :label="this.$t('subdistrict')"
        >
          <validation-provider
            #default="{ errors }"
            name="Sub District"
            rules="required"
          >
            <v-select
              id="h-sub-district-name"
              v-model="selectedSubDistrict"
              label="sub_district_name"
              :options="filteredSubDistricts"
              :state="errors.length > 0 ? false : null"
              :reduce="ele => ele.tumbon_id"
              @input="updatePostalcode"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-postal-code"
          :label="this.$t('postalCode')"
        >
          <validation-provider
            #default="{ errors }"
            name="Postal Code"
            rules="required"
          >
            <b-form-input
              id="h-postal-code"
              v-model="postalCode"
              label="zipcode"
              :state="errors.length > 0 ? false : null"
              :disabled="postalCode"
            />
            <!-- <div
              v-for="item in CalpostalCode(subdistrict)"
              :key="item.tum_id"
            >
              <div
                v-if="postalcode = item.zipcode"
                id="zipcode"
              /> -->
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </validation-observer>
      <template #modal-footer="{ cancel }">
        <b-button @click="copycart(index)">
          {{ $t('key-240') }}
        </b-button>
        <b-button
          variant="danger"
          @click="cancel"
        >
          {{ $t('cancel') }}
        </b-button>
        <b-button
          variant="primary"
          @click="addAddr(index)"
        >
          {{ $t('key-124') }}
        </b-button>
      </template>
    </b-modal>
    <!-- เพิ่มที่อยู่บัตรประชาชน -->
    <b-modal
      id="add-addr-cart"
      :title="$t('key-241')"
      no-close-on-backdrop
      @ok.prevent="addcart()"
    >
      <validation-observer ref="addRulescart">

        <b-form-group
          label-for="h-address-cart"
          :label="this.$t('addressNumber')"
        >
          <validation-provider
            #default="{ errors }"
            name="Address-cart"
            rules="required"
          >
            <b-form-textarea
              id="h-address-cart"
              v-model="addressCart"
              row="2"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label-for="h-province-name-cart"
          :label="this.$t('province')"
        >
          <validation-provider
            #default="{ errors }"
            name="Province-cart"
            rules="required"
          >
            <v-select
              id="h-province-name-cart"
              v-model="selectedProvinceCart"
              label="province_name"
              :options="provinceOptions"
              :state="errors.length > 0 ? false : null"
              :reduce="ele => ele.province_id"
              @input="updateDistrictsCart"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-district-name-cart"
          :label="this.$t('district')"
        >
          <validation-provider
            #default="{ errors }"
            name="District-cart"
            rules="required"
          >
            <v-select
              id="h-district-name-cart"
              v-model="selectedDistrictCart"
              label="district_name"
              :options="filteredDistrictsCart"
              :state="errors.length > 0 ? false : null"
              :reduce="ele => ele.district_id"
              @input="updateSubDistrictsCart"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-sub-district-name-cart"
          :label="this.$t('subdistrict')"
        >
          <validation-provider
            #default="{ errors }"
            name="Sub District"
            rules="required"
          >
            <v-select
              id="h-sub-district-name-cart"
              v-model="selectedSubDistrictCart"
              label="sub_district_name"
              :options="filteredSubDistrictsCart"
              :state="errors.length > 0 ? false : null"
              :reduce="ele => ele.tumbon_id"
              @input="updatePostalcodeCart"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-postal-code-cart"
          :label="this.$t('postalCode')"
        >
          <validation-provider
            #default="{ errors }"
            name="Postal Code-cart"
            rules="required"
          >
            <b-form-input
              id="h-postal-code-cart"
              v-model="zipcodeCart"
              label="zipcode"
              :state="errors.length > 0 ? false : null"
              :disabled="zipcodeCart"
            />
            <!-- <div
              v-for="item in CalpostalCode(subdistrict)"
              :key="item.tum_id"
            >
              <div
                v-if="postalcode = item.zipcode"
                id="zipcode"
              /> -->
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </validation-observer>
    </b-modal>
    <!-- modal edit bank -->
    <b-modal
      id="modal-receive-add-amend"
      no-close-on-backdrop
      :title="titleBank"
      :ok-title="$t('key-13')"
      :cancel-title="$t('cancel')"
      @ok.prevent="addNewbank()"
    >
      <validation-observer ref="addNewbank">
        <b-row>
          <b-col md="12">
            <b-form-group
              label-for="h-name_bank"
              label-cols="3"
              label-cols-lg="3"
              label-cols-md="3"
            >
              <template v-slot:label>
                <span class="text-danger"> *</span> {{ $t("key-55") }}
              </template>
              <v-select
                v-model="bank_obj"
                class="style-chooser"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="itemBankList"
              >
                <template #option="{ name, path_photo }">
                  <img
                    :src="`${path_photo}`"
                    :alt="name"
                    height="30"
                    class="mr-50 rounded-sm"
                  >
                  <span>{{ name }}</span>
                </template>

                <template #selected-option="{ name, path_photo }">
                  <img
                    :src="`${path_photo}`"
                    :alt="name"
                    height="30"
                    class="mr-50"
                  >
                  <span>{{ name }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label-for="h-addresstex"
              label-cols="3"
              label-cols-lg="3"
              label-cols-md="3"
            >
              <template v-slot:label>
                <span class="text-danger"> *</span>
                {{ $t("accountName") }}
              </template>

              <validation-provider
                #default="{ errors }"
                name="name_account"
                rules="required"
              >
                <b-form-input
                  id="h-name_bank"
                  v-model="name_account"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? $t("requiredField") + "!" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label-for="h-number_account"
              label-cols="3"
              label-cols-lg="3"
              label-cols-md="3"
            >
              <template v-slot:label>
                <span class="text-danger"> *</span>
                {{ $t("accountNumber") }}
              </template>

              <validation-provider
                #default="{ errors }"
                name="number_account"
                rules="required"
              >
                <b-form-input
                  id="h-number_account"
                  v-model="number_account"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? $t("requiredField") + "!" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BModal,
  BInputGroup,
  BInputGroupAppend,
  BTable,
  BFormRadio,
  BButton,
  BCardText,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
// import moment from 'moment-timezone'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BModal,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BFormRadio,
    BButton,
    BCardText,
    BFormCheckbox,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,
      ReferralCode: false,
      ReferralCodeValue: '',
      fullname: null,
      tel: null,
      tel2: null,
      different: null,
      invited_by: null,
      role: null,
      username: null,
      password: null,
      sub_role: [],
      Address: [],

      // Add Address
      name: null,
      phone: null,
      address: null,
      province: null,
      district: null,
      subdistrict: null,
      postalcode: null,
      provinceCart: null,

      items: null,
      invite: false,
      groupuser: [],

      index: null,
      del_address: [],

      selectedFiles: [],
      uploadedImages: [],
      fieldsCar: [
        { key: 'car', label: this.$t('truck'), thStyle: { width: '50%' } },
        { key: 'kg', label: this.$t('kilo'), thStyle: { width: '25%' } },
        { key: 'queue', label: this.$t('key-18'), thStyle: { width: '25%' } },
      ],
      fieldsShip: [
        { key: 'ship', label: this.$t('ship'), thStyle: { width: '50%' } },
        { key: 'kg', label: this.$t('kilo'), thStyle: { width: '25%' } },
        { key: 'queue', label: this.$t('key-18'), thStyle: { width: '25%' } },
      ],
      itemsCar: [
        {
          car: 'A', kg: 15, queue: 6500, key_kg: 'car_a_kilo', key_q: 'car_a_q',
        },
        {
          car: 'B', kg: 30, queue: 7000, key_kg: 'car_b_kilo', key_q: 'car_b_q',
        },
        {
          car: 'C', kg: 40, queue: 9000, key_kg: 'car_c_kilo', key_q: 'car_c_q',
        },
        {
          car: 'D', kg: 100, queue: 12000, key_kg: 'car_d_kilo', key_q: 'car_d_q',
        },
        {
          car: 'D1', kg: 180, queue: 15000, key_kg: 'car_d1_kilo', key_q: 'car_d1_q',
        },
        {
          car: 'S', kg: 70, queue: 8500, key_kg: 'car_s_kilo', key_q: 'car_s_q',
        },
      ],
      itemsShip: [
        {
          ship: 'A', kg: 10, queue: 4000, key_kg: 'ship_a_kilo', key_q: 'ship_a_q',
        },
        {
          ship: 'B', kg: 20, queue: 6000, key_kg: 'ship_b_kilo', key_q: 'ship_b_q',
        },
        {
          ship: 'C', kg: 30, queue: 9000, key_kg: 'ship_c_kilo', key_q: 'ship_c_q',
        },
      ],
      // subrole: [
      //   { name: 'ผู้ดูแล - รายการสั่งซื้อ', level: 'order', status: false },
      //   { name: 'ผู้ดูแล - ผู้ใช้งาน', level: 'user', status: false },
      //   { name: 'ผู้ดูแล - สิทธิ์การเข้าใช้งาน', level: 'settingrole', status: false },
      //   { name: 'ผู้ดูแล - กลุ่มผู้ใช้งาน', level: 'settingusertype', status: false },
      //   { name: 'ผู้ดูแล - รหัสพัสดุ', level: 'postnumber', status: false },
      //   { name: 'ผู้ดูแล - เติมเงินเข้าระบบ', level: 'refill', status: false },
      //   { name: 'ผู้ดูแล - ถอนเงินออกจากระบบ', level: 'withdraw', status: false },
      //   { name: 'ผู้ดูแล - สมุดบัญชี', level: 'balance', status: false },
      //   // { name: 'ผู้ดูแล - ใบเสร็จ', level: 'settingrole', status: false },
      //   { name: 'ผู้ดูแล - ฝากจ่ายเถาเป่า', level: 'payfor', status: false },
      //   // { name: 'ผู้ดูแล - ร้านค้าคืนเงิน', level: 'settingrole', status: false },
      //   { name: 'ผู้ดูแล - เติมอาลีเพย์', level: 'alipay', status: false },
      //   { name: 'ผู้ดูแล - โอนเงินไปจีน', level: 'transfer', status: false },
      //   // { name: 'ผู้ดูแล - สมุดเงินหยวน', level: 'settingrole', status: false },
      //   // { name: 'ผู้ดูแล - แลกหยวนเป็นบาท', level: 'settingrole', status: false },
      //   // { name: 'ผู้ดูแล - ชำระค่านำเข้า', level: 'settingrole', status: false },
      //   // { name: 'ผู้ดูแล - รายการส่งออกสินค้า', level: 'settingrole', status: false },
      //   // { name: 'ผู้ดูแล - สร้างใบชำระให้ลูกค้า', level: 'settingrole', status: false },
      //   { name: 'ผู้ดูแล - แจ้งสินค้าไม่ได้', level: 'productfailed', status: false },
      //   { name: 'ผู้ดูแล - สินค้าไม่มีเจ้าของ', level: 'ownerlessproduct', status: false },
      //   { name: 'ผู้ดูแล - ล็อตสินค้า', level: 'lot', status: false },
      //   // { name: 'ผู้ดูแล - รับสินค้าเข้าไทย', level: 'settingrole', status: false },
      //   { name: 'ผู้ดูแล - ข้อมูลโกดัง', level: 'warehouse', status: false },
      //   // { name: 'ผู้ดูแล - รายการแต้ม', level: 'settingrole', status: false },
      //   // { name: 'ผู้ดูแล - แลกแต้ม', level: 'settingrole', status: false },
      //   { name: 'ผู้ดูแล - รายการสินค้า', level: 'mall-list', status: false },
      //   // { name: 'ผู้ดูแล - สต๊อคคงเหลือ', level: 'settingrole', status: false },
      //   { name: 'ผู้ดูแล - ประเภทสินค้า', level: 'product-type', status: false },
      //   { name: 'ผู้ดูแล - ประเภทการจัดส่ง', level: 'shipping-type', status: false },
      //   { name: 'ผู้ดูแล - บัญชีธนาคาร', level: 'bank', status: false },
      //   { name: 'ผู้ดูแล - ทั่วไป', level: 'general', status: false },
      //   { name: 'ผู้ดูแล - โปรโมชั่น', level: 'promotion', status: false },
      //   // { name: 'ผู้ดูแล - ส่งของไปจีน', level: 'settingrole', status: false },
      //   // { name: 'ผู้ดูแล - ประกาศ', level: 'settingrole', status: false },
      //   // { name: 'ผู้ดูแล - รายงาน', level: 'settingrole', status: false },
      //   // { name: 'ผู้ดูแล - ส่วนลดล็อตสินค้า', level: 'settingrole', status: false },
      //   // { name: 'ผู้ดูแล - ประเภทการจัดส่ง(รถ/เรือ)', level: 'settingrole', status: false },
      //   // { name: 'ผู้ดูแล - คำถามที่พบบ่อย', level: 'settingrole', status: false },
      //   // { name: 'ผู้ดูแล - คูปอง', level: 'settingrole', status: false },
      //   { name: 'ผู้ดูแล - รวมรหัสพัสดุ', level: 'tracking-all', status: false },
      //   { name: 'ผู้ดูแล - แก้ไขเรทรายการสั่งซื้อ', level: 'order-rate', status: false },
      //   // { name: 'ผู้ดูแล - ปลดล็อคถอนเงิน', level: 'settingrole', status: false },
      //   // { name: 'ผู้ดูแล - ระบบ QC', level: 'settingrole', status: false },
      //   // { name: 'ผู้ดูแล - ปิดงานโกดัง', level: 'settingrole', status: false },
      //   // { name: 'ผู้ดูแล - บทความ', level: 'settingrole', status: false },
      //   { name: 'ผู้ดูแล - พนักงาน', level: 'employee', status: false },
      //   // { name: 'สมาชิก - ผู้ใช้งาน', level: 'settingrole', status: false },
      //   { name: 'สมาชิก - รหัสพัสดุ', level: 'tracking', status: false },
      //   { name: 'รายการสั่งซื้อ', level: 'order-list', status: false },
      //   // { name: 'เติมเงิน', level: 'settingrole', status: false },
      //   // { name: 'ชำระค่านำเข้า', level: 'settingrole', status: false },
      //   // { name: 'สมุดบัญชี', level: 'settingrole', status: false },
      //   // { name: 'รหัสพัสดุ', level: 'settingrole', status: false },
      //   // { name: 'อาลีเพย์', level: 'settingrole', status: false },
      //   // { name: 'โอนเงินไปจีน', level: 'settingrole', status: false },
      //   // { name: 'แจ้งไม่ได้รับสินค้า', level: 'settingrole', status: false },
      //   // { name: 'ฝากจ่ายเถาเป่า', level: 'settingrole', status: false },
      //   // { name: 'ร้านค้าคืนเงิน', level: 'settingrole', status: false },
      //   // { name: 'สมุดบัญชีเงินหยวน', level: 'settingrole', status: false },
      //   // { name: 'แต้ม', level: 'settingrole', status: false },
      //   // { name: 'สินค้าไม่มีเจ้าของ', level: 'settingrole', status: false },
      //   // { name: 'ANAN MALL', level: 'settingrole', status: false },
      // ],
      subrole: [],

      ID: this.$route.params.id,
      module_list: [],
      module_use: '',
      group_user_list: [],
      group_use: '',
      group_user_type: 1,
      group_userloading: false,
      itemspvc: [],
      idistrict: [],
      iSubdistrict: [],
      izipcode: [],
      subDistrict: [],
      postalCode: null,
      selectedProvince: null,
      selectedDistrict: null,
      selectedSubDistrict: null,
      bank_obj: null,
      itemBank: [],
      itemBankList: [],
      titleBank: null,
      selectedProvinceCart: null,
      selectedDistrictCart: null,
      selectedSubDistrictCart: null,
      addressCart: null,
      zipcodeCart: null,
      id: null,
    }
  },
  computed: {
    rolelist() {
      return [
        { text: 'แอดมิน', value: 1 },
        { text: this.$t('customer'), value: 0 },
        { text:  this.$t('key-258'), value: 2 },
      ]
    },
    fieldsbank() {
      return [
        {
          key: 'set',
          label: '',
          thStyle: { width: '5%' },
          thClass: 'text-center',
        },
        {
          key: 'name_bank',
          label: this.$t('key-55'),
          thStyle: { width: '15%' },
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'name_account',
          label: this.$t('accountName'),
          thStyle: { width: '15%' },
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'number_account',
          label: this.$t('accountNumber'),
          thStyle: { width: '15%' },
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        // {
        //   key: 'branch', label: this.$t('branch'), thStyle: { width: '15%' }, tdClass: 'text-center', thClass: 'text-center',
        // },
        {
          key: 'actions',
          label: this.$t('manage'),
          thStyle: { width: '10%' },
          tdClass: 'text-center',
          thClass: 'text-center',
        },

      ]
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    provinceOptions() {
      return this.itemspvc.map(item => ({
        province_id: item.province_id,
        province_name: item.province_name,
      }))
    },
    filteredDistricts() {
      if (this.selectedProvince) {
        return this.idistrict.filter(ele => (ele.province_id === this.selectedProvince))
          .map(item => ({
            district_id: item.district_id,
            district_name: item.district_name,
            province_id: item.province_id,
          }))
      }
      return this.idistrict.map(item => ({
        district_id: item.district_id,
        district_name: item.district_name,
        province_id: item.province_id,
      }))
    },
    filteredDistrictsCart() {
      if (this.selectedProvinceCart) {
        return this.idistrict.filter(ele => (ele.province_id === this.selectedProvinceCart))
          .map(item => ({
            district_id: item.district_id,
            district_name: item.district_name,
            province_id: item.province_id,
          }))
      }
      return this.idistrict.map(item => ({
        district_id: item.district_id,
        district_name: item.district_name,
        province_id: item.province_id,
      }))
    },
    filteredSubDistricts() {
      if (this.selectedDistrict) {
        return this.iSubdistrict.filter(ele => (ele.ampur_id === this.selectedDistrict))
          .map(item => ({
            tumbon_id: item.tumbon_id,
            sub_district_name: item.sub_district_name,
            ampur_id: item.ampur_id,
            changwat_id: item.changwat_id,
          }))
      }
      if (this.selectedProvince) {
        return this.iSubdistrict.filter(ele => (ele.changwat_id === this.selectedProvince))
          .map(item => ({
            tumbon_id: item.tumbon_id,
            sub_district_name: item.sub_district_name,
            ampur_id: item.ampur_id,
            changwat_id: item.changwat_id,
          }))
      }
      return this.iSubdistrict.map(item => ({
        ampur_id: item.ampur_id,
        sub_district_name: item.sub_district_name,
        tumbon_id: item.tumbon_id,
        changwat_id: item.changwat_id,
      }))
    },
    filteredSubDistrictsCart() {
      if (this.selectedDistrictCart) {
        return this.iSubdistrict.filter(ele => (ele.ampur_id === this.selectedDistrictCart))
          .map(item => ({
            tumbon_id: item.tumbon_id,
            sub_district_name: item.sub_district_name,
            ampur_id: item.ampur_id,
            changwat_id: item.changwat_id,
          }))
      }
      if (this.selectedProvinceCart) {
        return this.iSubdistrict.filter(ele => (ele.changwat_id === this.selectedProvinceCart))
          .map(item => ({
            tumbon_id: item.tumbon_id,
            sub_district_name: item.sub_district_name,
            ampur_id: item.ampur_id,
            changwat_id: item.changwat_id,
          }))
      }
      return this.iSubdistrict.map(item => ({
        ampur_id: item.ampur_id,
        sub_district_name: item.sub_district_name,
        tumbon_id: item.tumbon_id,
        changwat_id: item.changwat_id,
      }))
    },
    filteredpostalCode() {
      if (this.selectedSubDistrict) {
        return this.izipcode.filter(ele => (ele.tum_id === this.selectedSubDistrict))
          .map(item => ({
            tum_id: item.tum_id,
            zipcode: item.zipcode,
          }))
      }
      return this.izipcode.map(item => ({
        tum_id: item.tum_id,
        zipcode: item.zipcode,
      }))
    },
  },
  async mounted() {
    this.sub_role = []
    await this.Getdata()
    await this.ShowUser()
    this.GetSubrole()
    this.GetModuleList()
    this.GetGroupUserList()
    this.getProvince()
    this.getDistrict()
    this.getSubdistrict()
    this.getzipcode()
    this.getBank()
    this.getSelect()
  },
  methods: {
    addNewbank() {
      this.$refs.addNewbank.validate().then(success => {
        if (success) {
          const obj = {
            id: this.id,
            uid: this.ID,
            username: this.items.user.username,
            name_bank: this.bank_obj.name,
            bank_code: this.bank_obj.bankcode,
            name_account: this.name_account,
            number_account: this.number_account,
            branch: this.branch,
          }
          this.$http
            .post('/Refill/StoreBankAdmin', obj)
            .then(() => {
              this.$bvModal.hide('modal-receive-add-amend')
              this.Success(this.$t('key-215'))
              this.getBank()
            })
            .catch(err => {
              this.SwalError(err.response.data.message)
              console.log(err)
            })
        }
      })
    },
    async getSelect() {
      try {
        const { data: res } = await this.$http.get('/bank')
        this.itemBankList = res.map(ele => ({
          id: ele.id,
          bankcode: ele.bankcode,
          name: ele.name,
          path_photo: `/bankIcon/${ele.path_photo}`,
        }))
      } catch (e) {
        console.log(e)
      }
    },
    EditType(data) {
      if (data) {
        // eslint-disable-next-line no-underscore-dangle
        this.id = data._id
        this.name_bank = data.name_bank
        this.name_account = data.name_account
        this.number_account = data.number_account
        this.branch = data.branch
        const FindBank = this.itemBankList.find(
          ele => ele.bankcode === data.bank_code,
        )
        this.bank_obj = FindBank
      } else {
        this.id = null
        this.name_bank = null
        this.name_account = null
        this.number_account = null
        this.branch = null
        this.bank_obj = null
      }
      this.$bvModal.show('modal-receive-add-amend')
    },
    addcart() {
      this.$refs.addRulescart.validate().then(success => {
        if (success) {
          this.items.user.address = this.addressCart
          this.items.user.province = this.selectedProvinceCart
          this.items.user.district = this.selectedDistrictCart
          this.items.user.subdistrict = this.selectedSubDistrictCart
          this.items.user.zipcode = this.zipcodeCart
          if (this.selectedProvinceCart) {
            const provi = this.itemspvc.find(ele => (ele.province_id === this.selectedProvinceCart))
            this.items.user.province_name = provi.province_name
          }
          if (this.selectedDistrictCart) {
            const arr = this.idistrict.find(ele => (ele.district_id === this.selectedDistrictCart))
            this.items.user.district_name = arr.district_name
          }
          if (this.selectedSubDistrictCart) {
            const arr = this.iSubdistrict.find(ele => (ele.tumbon_id === this.selectedSubDistrictCart))
            this.items.user.subdistrict_name = arr.sub_district_name
          }
          this.$bvModal.hide('add-addr-cart')
        }
      })
    },
    DeleteData(data) {
      this.$bvModal
        .msgBoxConfirm(this.$t('key-216'), {
          title: this.$t('key-217'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(result => {
          if (result) {
            const obj = {
              // eslint-disable-next-line no-underscore-dangle
              id: data._id,
            }
            this.$http
              .post('/Refill/DeleteBank', obj)
              .then(() => {
                this.getBank()
                this.Success(this.$t('key-218'))
              })
              .catch(err => {
                console.log(err)
                this.SwalError(err)
              })
          }
        })
    },
    async getBank() {
      try {
        const params = {
          id: this.$route.params.id,
        }
        const { data: res } = await this.$http.get('/Refill/getBankUser', { params })
        this.itemBank = res
      } catch (err) {
        console.log(err)
      }
    },
    async Getdata() {
      try {
        const res = await this.$http.get(`/users/showByusername/${this.$route.params.id}`)
        this.items = res.data
        // this.provinceCart =  this.items.user.province

        // this.itemspvc.map(item => ({
        //   province_id: item.province_id,
        //   province_name: item.province_name,
        // }))
      } catch (error) {
        console.log(error)
      }
    },
    async ShowUser() {
      try {
        const params = {
          ID: this.ID,
        }
        const { data: res } = await this.$http.get('/users/showUser', { params })
        this.groupuser = res
        const obj = {
          _id: this.groupuser.group_id,
          name: this.groupuser.group_name,
          product_type: this.groupuser.product_type,
        }
        this.group_use = obj
        this.group_user_type = this.groupuser.group_type
        // console.debug(this.groupuser)
      } catch (error) {
        console.log(error)
      }
    },
    async GetSubrole() {
      try {
        const { data: res } = await this.$http.get('/moduledetail/index')
        this.subrole = res
        this.subrole.forEach(item => {
          this.$set(item, 'status', false)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async GetModuleList() {
      try {
        const { data: res } = await this.$http.get('/modulelist/index')
        this.module_list = res.data
        if (this.items.user.module_id) {
          const moduleJs = this.module_list.find(item => item.id === this.items.user.module_id)
          if (moduleJs) this.module_use = moduleJs
          const x = moduleJs.sub_role.split(',')
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < x.length; i++) {
            // ถ้ามีค่าใน x ให้เช็คว่ามีค่าใน subrole ไหม ถ้ามีให้เปลี่ยน status เป็น true
            const index = this.subrole.findIndex(item => item.level === x[i])
            if (index >= 0) {
              this.sub_role.push(x[i])
              this.subrole[index].status = true
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async GetGroupUserList() {
      try {
        this.group_userloading = true
        const { data: res } = await this.$http.get('/groupbyuser/list/index')
        // const params = {
        //   ID: this.groupuser.group_id,
        // }
        // const { data: re } = await this.$http.get('/groupbyuser/list/show', { params })
        this.group_user_list = res
        this.group_userloading = false
        // eslint-disable-next-line no-underscore-dangle
        // this.group_user_list.find(item => item._id === this.groupuser.group_id)
        // const groupuseJs = this.groupuser

        // this.group_use = obj
        // groupuseJs.product_type.sort((a, b) => {
        //   if (a.product_type_class < b.product_type_class) return -1;
        //   if (a.product_type_class > b.product_type_class) return 1;
        //   const aName = a.product_type_name.match(/\(([^)]+)\)/)[1].trim();
        //   const bName = b.product_type_name.match(/\(([^)]+)\)/)[1].trim();

        //   return bName.localeCompare(aName);
        // });
        // this.group_user_type = this.groupuser.group_type
        // if (this.group_user_type === 2) {
        //   groupuseJs.product_type = this.groupuser.product_type
        // }
        // if (this.group_user_type === 1) {
        //   this.groupuser.product_type = groupuseJs.product_type
        // }
        // if (groupuseJs) this.group_use = obj
        // eslint-disable-next-line no-underscore-dangle
        // this.groupuser.product_type = groupuseJs.product_type
        // this.group_use.product_type =
        // if (this.group_user_type) {
        //   console.log(this.group_use)
        //   // eslint-disable-next-line
        //   for (const Index in this.preorder(this.group_use.product_type, 1)) {
        //     this.itemsCar[Index].product_type_kilo = this.items.groupuser[this.itemsCar[Index].product_type_kilo]
        //     this.itemsCar[Index].product_type_queue = this.items.groupuser[this.itemsCar[Index].product_type_queue]
        //   }
        // eslint-disable-next-line
        // for (const Index in this.itemsShip) {
        //       this.itemsShip[Index].product_type_kilo = this.items.groupuser[this.itemsShip[Index].product_type_kilo]
        //       this.itemsShip[Index].product_type_queue = this.items.groupuser[this.itemsShip[Index].product_type_queue]
        //     }
        //   }
      } catch (error) {
        console.log(error)
      }
    },
    preorder(item, Type) {
      if (item) {
        const re = item.filter(ele => ele.product_type_class === Type)
        re.sort((a, b) => {
          if (a.product_type_initial < b.product_type_initial) {
            return 1
          } if (a.product_type_initial > b.product_type_initial) {
            return -1
          }
          return 0
        })
        // re.sort((a, b) => {
        //   if (a.product_type_class < b.product_type_class) return -1;
        //   if (a.product_type_class > b.product_type_class) return 1;
        //   const aName = a.product_type_name.match(/\(([^)]+)\)/)[1].trim();
        //   const bName = b.product_type_name.match(/\(([^)]+)\)/)[1].trim();
        //   return bName.localeCompare(aName);
        // });
        return re
      }
      return []
    },
    // CalGroupUser() {
    // @input="CalGroupUser()"
    // eslint-disable-next-line no-underscore-dangle

    // },
    addAddr(index) {
      this.$refs.addRules.validate().then(success => {
        if (success) {
          if (index != null && index >= 0) {
            const Obj = {
              id: this.items.addresses[index].id,
              uid: this.items.addresses[index].uid,
              fullname: this.fullname,
              tel: this.tel,
              address: this.address,
              province: '',
              province_id: this.selectedProvince,
              district: '',
              district_id: this.selectedDistrict,
              subdistrict: '',
              subdistrict_id: this.selectedSubDistrict,
              postalcode: this.postalCode,
            }
            if (this.selectedProvince) {
              const provi = this.itemspvc.find(ele => (ele.province_id === this.selectedProvince))
              Obj.province = provi.province_name
            }
            if (this.selectedDistrict) {
              const arr = this.idistrict.find(ele => (ele.district_id === this.selectedDistrict))
              Obj.district = arr.district_name
            }
            if (this.selectedSubDistrict) {
              const arr = this.iSubdistrict.find(ele => (ele.tumbon_id === this.selectedSubDistrict))
              Obj.subdistrict = arr.sub_district_name
            }
            this.items.addresses[index] = Obj
            this.$set(this.items.addresses, index, Obj)
            this.$bvModal.hide('add-addr')
          } else {
            const Obj = {
              fullname: this.fullname,
              tel: this.tel,
              address: this.address,

              province: '',
              province_id: this.selectedProvince,
              district: '',
              district_id: this.selectedDistrict,
              subdistrict: '',
              subdistrict_id: this.selectedSubDistrict,
              postalcode: this.postalCode,
            }
            if (this.selectedProvince) {
              const provi = this.itemspvc.find(ele => (ele.province_id === this.selectedProvince))
              Obj.province = provi.province_name
            }
            if (this.selectedDistrict) {
              const arr = this.idistrict.find(ele => (ele.district_id === this.selectedDistrict))
              Obj.district = arr.district_name
            }
            if (this.selectedSubDistrict) {
              const arr = this.iSubdistrict.find(ele => (ele.tumbon_id === this.selectedSubDistrict))
              Obj.subdistrict = arr.sub_district_name
            }
            this.items.addresses.push(Obj)
            this.$bvModal.hide('add-addr')
          }
        }
      })
    },
    SubRole(data) {
      if (data.status) {
        this.sub_role.push(data.level)
      } else {
        this.sub_role = this.sub_role.filter(item => item !== data.level)
      }
      this.items.user.sub_role = this.sub_role.toString()
    },
    Submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (!this.group_use) {
            return this.SwalError(this.$t('selectUserGroup'))
          }
          const formData = {
            ID: this.ID,
            data: this.items,
            password: this.password,
            del_address: this.del_address,
            ProductType: {
              // eslint-disable-next-line no-underscore-dangle
              group_id: this.group_use._id,
              uid: this.groupuser.uid,
              username: this.groupuser.username,
              group_type: this.group_user_type,
              group_name: this.group_use.name,
              group_detail: this.group_use.detail,
              product_type: this.group_use.product_type,
            },

          }
          if (this.group_user_type === 1 || this.group_user_type === '1') {
            const data = this.group_user_list.find(item => item._id == this.group_use._id)
            formData.ProductType.group_name = data.name
            formData.ProductType.product_type = data.product_type
          }
          this.$http
            .post('/users/AdminUpdate', formData)
            .then(response => {
              if (response.data.status) {
                this.Success(this.$t('key-261'))
                this.$router.push({ name: 'admin-employee' })
              }
            })
            .catch(error => {
              // if () {

              // }
              this.SwalError(error.response.data.message)
            })
        }
      })
    },
    CalModule() {
      console.log(this.module_use)
      const moduleArr = this.module_use.sub_role.split(',')
      const EditRole = []
      this.subrole.forEach(item => {
        if (moduleArr.includes(item.level.toString())) {
          EditRole.push(item.level)
          this.$set(item, 'status', true)
        } else {
          this.$set(item, 'status', false)
        }
      })
      this.items.user.module_id = this.module_use.id
      this.items.user.sub_role = EditRole.toString()
    },
    updateDistricts() {
      this.selectedDistrict = null
      this.selectedSubDistrict = null
    },
    updateDistrictsCart() {
      this.selectedDistrictCart = null
      this.selectedSubDistrictCart = null
    },
    updateSubDistricts() {
      this.selectedSubDistrict = null
      this.postalCode = null
    },
    updateSubDistrictsCart() {
      this.selectedSubDistrictCart = null
      this.zipcodeCart = null
    },
    updatePostalcode() {
      const arr = this.izipcode.find(ele => (ele.tum_id === this.selectedSubDistrict))
      this.postalCode = arr.zipcode
    },
    updatePostalcodeCart() {
      const arr = this.izipcode.find(ele => (ele.tum_id === this.selectedSubDistrictCart))
      this.zipcodeCart = arr.zipcode
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-219')}!</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    async getProvince() {
      try {
        const { data: res } = await this.$http.get('/Addresscustomer/province') // จังหวัด
        this.itemspvc = res
      } catch (e) {
        console.log(e)
      }
    },
    async getDistrict() {
      try {
        const { data: res } = await this.$http.get('/Addresscustomer/district') // อำเภอ
        this.idistrict = res
      } catch (e) {
        console.log(e)
      }
    },
    async getSubdistrict() {
      try {
        const { data: res } = await this.$http.get('/Addresscustomer/Subdistrict') // ตำบล
        this.iSubdistrict = res
      } catch (e) {
        console.log(e)
      }
    },
    async getzipcode() {
      try {
        const { data: res } = await this.$http.get('/Addresscustomer/zipcode') // ตำบล
        this.izipcode = res
      } catch (e) {
        console.log(e)
      }
    },
    showAddAddr(data, index) {
      if (data) {
        this.index = index
        this.fullname = data.fullname
        this.tel = data.tel
        this.address = data.address
        this.selectedProvince = data.province_id
        this.selectedDistrict = data.district_id
        this.selectedSubDistrict = data.subdistrict_id
        this.postalCode = data.postalcode
      } else {
        this.index = null
        this.fullname = null
        this.tel = null
        this.address = null
        this.selectedProvince = null
        this.selectedDistrict = null
        this.selectedSubDistrict = null
        this.postalCode = null
      }
      this.$bvModal.show('add-addr')
    },
    showAddAddrCart(data) {
      if (data) {
        this.addressCart = data.address
        this.selectedProvinceCart = Number(data.province)
        this.selectedDistrictCart = Number(data.district)
        this.selectedSubDistrictCart = Number(data.subdistrict)
        this.zipcodeCart = data.zipcode
      } else {
        this.addressCart = null
        this.selectedProvinceCart = null
        this.selectedDistrictCart = null
        this.selectedSubDistrictCart = null
        this.zipcodeCart = null
      }
      this.$bvModal.show('add-addr-cart')
    },
    copycart(index) {
      this.index = index
      this.fullname = this.items.user.fullname
      this.tel = this.items.user.tel
      this.address = this.items.user.address
      this.selectedProvince = Number(this.items.user.province)
      this.selectedDistrict = Number(this.items.user.district)
      this.selectedSubDistrict = Number(this.items.user.subdistrict)
      this.postalCode = this.items.user.zipcode
    },
    deleteAddr(data, index) {
      this.del_address.push(data.id)
      this.items.addresses.splice(index, 1)
    },
    deleteAddrCart() {
      this.items.user.address = null
      this.items.user.province_name = null
      this.items.user.district_name = null
      this.items.user.subdistrict_name = null
      this.items.user.zipcode = null
    },
    showModal() {
      this.index = null
      this.fullname = null
      this.tel = null
      this.address = null
      this.province = null
      this.district = null
      this.subdistrict = null
      this.postalcode = null
      this.$bvModal.show('modal-1')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
